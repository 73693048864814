import * as React from "react"
import Navbar from "../components/Navbar";
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Footer";
import Hero from "../components/Hero";

// markup
const Partners = () => {
    return (
        <>
            <Helmet>
                <body className="page-partners" />
            </Helmet>

            <Navbar/>

            <main>
                <div className="hero-wrapper">
                    <Hero title="Partners" width="50"/>
                    <StaticImage src="../images/pexels-fauxels-3184418.jpg" alt="Over meneer Jansen header" className="hero-image" />
                </div>

                <article>
                    <div className="content">
                        <div className="cols">

                            <div className="col-xs-100 col-sm-50 col-md-25">
                            </div>

                            <div className="col-xs-100 col-sm-50 col-md-75">
                                <section>
                                    Samenwerken doe ik met:
                                </section>
                            </div>


                            <div className="col-xs-100 col-sm-50 col-md-25">
                                <section className="padding-right-2em">
                                    <StaticImage src="../images/Logo Visser en van Blanken.png" alt="Logo Visser en van Blanken" className="border-radius-0"/>
                                </section>
                            </div>

                            <div className="col-xs-100 col-sm-50 col-md-75">
                                <section className="text-based">
                                    <h3>Visser en van Blanken</h3>
                                    <p>
                                        Met Roeland Visser en Inge van Blanken werk ik samen aan nieuwe abonnementen met aanvullende Careweb functionaliteit.
                                        <br/><a href="https://visserenvanblanken.nl/" target="_blank" rel="noreferrer">https://visserenvanblanken.nl/</a>
                                    </p>
                                </section>
                            </div>
                        </div>

                        <div className="cols">

                            <div className="col-xs-100 col-sm-50 col-md-25">
                                <section className="padding-right-2em">
                                    <StaticImage src="../images/Careweb logo.jpg" alt="Logo Careweb" className="border-radius-0"/>
                                </section>
                            </div>

                            <div className="col-xs-100 col-sm-50 col-md-75">
                                <section className="text-based">
                                    <h3>Careweb</h3>
                                    <p>
                                        Careweb biedt een gebruiksvriendelijk, compleet en 100% webbased EPD en ZMS voor de GGZ en het sociaal domein.
                                        <br/><a href="https://careweb.nl/" target="_blank" rel="noreferrer">https://careweb.nl/</a>
                                    </p>
                                </section>
                            </div>
                        </div>

                        <div className="cols">

                            <div className="col-xs-100 col-sm-50 col-md-25">
                                <section className="padding-right-2em">
                                    <StaticImage src="../images/logo-bastiaan-van-haastrecht.png" alt="Logo Bastiaan van Haastrecht" className="border-radius-0"/>
                                </section>
                            </div>

                            <div className="col-xs-100 col-sm-50 col-md-75">
                                <section className="text-based">
                                    <h3>Bastiaan van Haastrecht</h3>
                                    <p>
                                        Bastiaan van Haastrecht is een programmeur die mee ontwikkelt aan de Careweb uitbreidingen.
                                    </p>
                                </section>
                            </div>
                            </div>


                    </div>
                </article>

            </main>

            <Footer />
        </>

    )
}

export default Partners;
